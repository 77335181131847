<template>
  <div class="wrapper_five container py-5" id="application">
    <div class="title">
      <h1>Обратная связь</h1>
    </div>

    <div class="form d-flex flex-column justify-content-start align-items-start">
      <p>Ваше полное ФИО (по удостоверению)</p>
      <input v-model="name" type="text" placeholder="Алмаз" required>

      <p class="pt-3">Телефон</p>
      <input v-mask="'+7(###)###-##-##'" v-model="phone" type="text" placeholder="+7 (777) 777-77-77" required>

      <p class="pt-3">Email</p>
      <input v-model="email" type="text" placeholder="Введите Ваш email адрес" required>

      <p class="pt-3 d-flex align-items-center">
        <input type="checkbox" v-model="hasPromoCode" id="promoCodeCheckbox">
        <label for="promoCodeCheckbox">Есть промокод?</label>
      </p>

      <div v-if="hasPromoCode" class="pt-3">
        <p>Промокод</p>
        <input v-model="promoCode" type="text" placeholder="Введите ваш промокод">
      </div>

      <button @click="submitForm" class="mt-5">Отправить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeedBackFormComponent",
  data(){
    return {
      name: '',
      phone: '',
      email: '',
      hasPromoCode: false,
      promoCode: ''
    };
  },
  methods: {
    async submitForm() {
      if(this.name=='' || this.phone=='' || this.email==''){
        alert("Заполните все поля");
      }
      else{
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('phone', this.phone); // Добавляем данные телефона
        formData.append('email', this.email);

        if (this.hasPromoCode) {
          formData.append('promoCode', this.promoCode);
        }

        try {
          const response = await fetch('https://adway.edu.kz/theme/space/ajax_form.php', { // Замените URL_API на фактический адрес вашего API
            method: 'POST',
            body: formData, // Используем FormData для отправки
          });
          const result = await response.json();
          if (result && result.result === 1) {
            alert('Форма успешно отправлена: ' + result.msg);
          } else {
            throw new Error(result.msg);
          }
        } catch (error) {
          console.error('Ошибка при отправке формы: ', error);
          alert('Ошибка при отправке формы: ' + error.message);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper_five {
  display: flex;

  .title {
    width: 50%;

    h1 {
      font-family: Manrope, sans-serif;
      font-weight: 700;
      font-size: 48px;
      line-height: 64px;
      color: #000;

      span {
        color: #2D6BBF;
      }
    }
    p {
      font-family: Manrope, sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #434343;
    }
  }
  .form {
    width: 50%;
    padding-left: 10%;
    input {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 24px;
      gap: 10px;
      width: 100%;
      height: 58px;
      background: #F9F9F9;
      border: 1px solid #F3F3F3;
      border-radius: 16px;
    }
    input[type="checkbox"]{
      width: 20px;
      height: 20px;
      margin-right:10px;
    }
    p {
      font-family: Manrope, sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: #8E8E93;
    }
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 32px;
      gap: 10px;
      width: 100%;
      height: 51px;
      background: #FFA51E;
      border-radius: 16px;
    }
  }
}

@media screen and (max-width: 430px) {
  .wrapper_five {
    display: flex;
    flex-wrap: wrap;

    .title {
      width: 100%;
      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #1A1A1A;
        span {
          color: #2D6BBF;
        }
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
      }
    }
    .form {
      margin-top: 10px;
      width: 100%;
      padding: 0;
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
      }
      input {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 10px;
        width: 358px;
        height: 58px;
        background: #F9F9F9;
        border: 1px solid #F3F3F3;
        border-radius: 16px;
      }
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 32px;
        gap: 10px;
        width: 358px;
        height: 46px;
        background: #FFA51E;
        border-radius: 16px;
      }
    }
  }
}
@media screen and (max-width: 390px) {
  .wrapper_five {
    display: flex;
    flex-wrap: wrap;

    .title {
      width: 100%;
      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #1A1A1A;
        span {
          color: #2D6BBF;
        }
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
      }
    }
    .form {
      margin-top: 10px;
      width: 100%;
      padding: 0;
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
      }
      input {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 10px;
        width: 358px;
        height: 58px;
        background: #F9F9F9;
        border: 1px solid #F3F3F3;
        border-radius: 16px;
      }
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 32px;
        gap: 10px;
        width: 358px;
        height: 46px;
        background: #FFA51E;
        border-radius: 16px;
      }
    }
  }
}
</style>